import { FormattedDate, FormattedMessage } from 'react-intl';
/* node_modules */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
/* local imports */
import { Link, generatePath, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteImage,
  getImageById,
} from '~/modules/gallery';

import Breadcrumbs from '~/components/Breadcrumbs';
import Collection from '~/components/Gallery/Image/Collection';
import CommentContainer from '~/components/Comments';
import Config from '~/config';
import Content from '~/components/Gallery/Image/Content';
import { LikeButton } from '~/components/Button';
import { TOGGLE_STATES } from '~/components/AdultToggle';
import UserCard from '~/components/UserCard';
import { getVisibilitySettings } from '~/modules/contentVisibility';
/* style imports */
import styles from './GalleryImage.scss';
import FeaturedToggle from '~/components/FeaturedToggle';
import { ReactMarkdownWithHtml } from '~/components/Markdown';

const cx = classNames.bind(styles);

const extractTextContent = (description) => {
  if (!description) {
    return 'An art streaming and gallery service with free multistreaming and no ads';
  }

  const res = description.replace(/(<([^>]+)>)/ig, '').replace(/\r?\n|\r/g, ' ').slice(0, 297);
  let end = '';
  if (res.length === 297) {
    end = '...';
  }

  return `${res}${end}`;
};

/**
 * A wrapper to display gallery content
 */
const GalleryImage = () => {
  const { imageId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isSignedIn, data: currentUser } = useSelector(state => state.currentUser);
  const adultToggleState = useSelector(state => getVisibilitySettings(state));
  const image = useSelector(state => getImageById(state, parseInt(imageId || '', 10)));
  const {
    id,
    title,
    description,
    status,
    user: {
      username,
      avatar: { url: avatarUrl },
    },
    image: {
      url: imageUrl,
      thumb,
    },
    thumbnail,
  } = image;


  useEffect(() => {
    fetch(`${Config.api}/gallery/ping/${image.id}`);
  }, []);

  const confirmDelete = useCallback(() => {
    if (window.confirm('Delete this image?')) {
      dispatch(deleteImage(image.id)).then(() => {
        navigate(generatePath('/gallery/:username', { username: image.user.username }));
      });
    }
  }, [dispatch, image]);
 
  const breadcrumbLinks = useMemo(() => {
    const links = [
      { url: `/gallery/${username}`, title: username, image: avatarUrl },
    ];

    if (image.folder) {
      links.push({
        url: `/gallery/${username}/${image.folder.id}`,
        title: image.folder.name,
      });
    }

  
    links.push({ url: `/gallery/image/${id}`, title });

    return links;
  }, [image]);

  const isAdmin = isSignedIn && (currentUser.role === 'admin');
  const canEdit = isAdmin || (isSignedIn && (username === currentUser.username));
  const largeImg = image.image.large?.url;
  const thumbUrl = largeImg || Object.values(image.image).find(img => img.url)?.url;

  return (
    <div>
      <Helmet
        title={title}
        meta={[
          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'twitter:image', content: `${thumbUrl}` },
          { property: 'og:image', content: `${thumbUrl}` },
          { name: 'twitter:title', content: `${title} by ${username} - Piczel.tv` },
          { property: 'og:title', content: `${title} by ${username} - Piczel.tv` },
          { name: 'twitter:description', content: `${extractTextContent(description)}` },
          { property: 'og:description', content: `${extractTextContent(description)}` },
        ]}
      />

      <Breadcrumbs links={breadcrumbLinks} />

      <div className={styles.GalleryImage__Content}>
        {image.multi
          ? <Collection image={image} blur={adultToggleState <= TOGGLE_STATES.BLUR && (image.adult || image.nsfw)} />
          : (
            <Content
              {...image}
              blur={adultToggleState <= TOGGLE_STATES.BLUR && (image.adult || image.nsfw)}
            />
          )}
      </div>
      <div className={styles.GalleryImage__Info}>
        <div className={styles.GalleryImage__Topline}>
          <UserCard
            className={styles.GalleryImage__UserCard}
            followButton
            user={image.user}
            to={`/gallery/${image.user.username}`}
          />
          <div className={styles.GalleryImage__Stats}>
            <span className={styles.GalleryImage__StatsItem}>
              {image.published_at && <PublishedDate publishedAt={image.published_at} isPublished={status === "published"}/>}
            </span>

            <span
              className={styles.GalleryImage__StatsItem}
              title={`Add${image.favorite ? 'ed' : ''} to favorites`}
            >
              <LikeButton image={image} />
            </span>

            <span className={styles.GalleryImage__StatsItem}>
              <i className="ion-eye" />
              &nbsp;
              <FormattedMessage
                id="Gallery_ViewImage_Views"
                defaultMessage="{viewCount, number} {viewCount, plural, one {view} other {views}}"
                values={{ viewCount: image.views }}
              />
            </span>
          </div>
        </div>
        <div className={styles.GalleryImage__Description}>
          <h2>{image.title}</h2>
          <div>
            <ReactMarkdownWithHtml children={image.description}/>
          </div>
          <div className={styles.GalleryImage__Tags}>
            {image.tags
              && image.tags.map((tag, i) => (
                <Link
                  className="GalleryImage_SingleTag"
                  key={tag.title}
                  to={`/search/gallery/${encodeURIComponent(tag.title)}`}
                >
                  <i className="ion-pricetag" />
                  {' '}
                  {tag.title}
                </Link>
              ))}
          </div>
          {canEdit && (
            <div className={styles.GalleryImage__Settings}>
              <span
                className={styles.GalleryImage__SettingsAction}
                onClick={confirmDelete}
              >
                <i className="ion-trash-a" title="Delete image" />
                Delete
              </span>
              <Link
                to={`/gallery/image/${id}/edit`}
                className={styles.GalleryImage__SettingsAction}
              >
                <i className="ion-edit" title="Edit image" />
                Edit
              </Link>
              {
                isAdmin && (
                  <FeaturedToggle image={image} />
                )
              }
            </div>
          )}
        </div>
        <CommentContainer owner={username} type="GalleryImage" id={id} className={styles.GalleryImage__CommentContainer} />
      </div>
    </div>
  );
};

const PublishedDate = ({publishedAt, isPublished}) => {

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return null;
  }

  const date = (
    <FormattedDate
      value={new Date(publishedAt)}
      month="long"
      day="numeric"
      year="numeric"
      minute="numeric"
      hour="numeric"
    />
  );

  if(isPublished){
    return date;
  };

  return <FormattedMessage id="Gallery_ViewImage_ScheduledPublished" defaultMessage="This image is scheduled to be published on {date}, only you can see this image" values={{date}}/>
}


export default GalleryImage;
