/* node_modules */
import React from 'react';
import Helmet from 'react-helmet';
import {
  Route, Routes as Switch, redirect,
} from 'react-router-dom';
import loadable from '@loadable/component';
import App from './app';

/* local modules */
import StreamGuard, { StreamGuardHydrate } from './views/StreamGuard';
import SignOut from './views/SignOut';
import BulkUpload from './views/BulkUpload';
import StandaloneChat from './views/StandaloneChat';
import Watch from './views/Watch';
import Gallery from '~/views/Gallery';
import GalleryImage, { GalleryImageHydrate } from '~/views/Gallery/Image';
// import ContestResults from '~/views/ContestResults';
import Player from '~/views/Player';
import DebugPlayer from './views/DebugPlayer';
import Home from '~/views/Home';
import ErrorBoundary from './components/ErrorBoundary';

// const Home = loadable(() => import('~/views/Home'));
const Login = loadable(() => import('~/views/Login'));
const SignUp = loadable(() => import('~/views/SignUp'));
const Account = loadable(() => import('~/views/Account'));
const NotFound = loadable(() => import('~/views/404'));
const Premium = loadable(() => import('~/views/Premium'));
const Search = loadable(() => import('~/views/Search'));
const AdminPanel = loadable(() => import(/* webpackChunkName: 'Admin' */'~/views/Admin'));
const Tos = loadable(() => import(/* webpackChunkName: 'Tos' */'./pages/tos.mdx'));
const Privacy = loadable(() => import(/* webpackChunkName: 'Privacy' */'./pages/privacy.mdx'));
const Help = loadable(() => import('~/views/Help'));
const Style = loadable(() => import(/* webpackChunkName: 'StyleGuide' */'./pages/styleguide.mdx'));
const Optimization = loadable(() => import('~/views/Optimization'));
const Streams = loadable(() => import('~/views/Home/Streams'));
const Feed = loadable(() => import('~/views/Home/Feed'));
const HomeGallery = loadable(() => import('~/views/Home/Gallery'));

export const Routes = [
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      // Home routes
      {
        path: '/',
        element: <Home />,
        children: [
          { index: true, element: <Feed /> },
        ],
      },
      {
        path: '/streams',
        element: <Home />,
        children: [
          { index: true, element: <Streams /> },
        ],
      },
      {
        path: '/gallery',
        element: <Home />,
        children: [
          { index: true, element: <HomeGallery /> },
        ],
      },
      {
        path: '/feed',
        element: <Home />,
        children: [
          { index: true, element: <Feed /> },
        ],
      },

      // Gallery routes
      {
        path: '/gallery/image/:imageId/*',
        element: <GalleryImage />,
        hydrate: GalleryImageHydrate,
      },
      {
        path: '/gallery/*',
        element: <Gallery />,
        children: [
          { path: 'upload', element: <Gallery /> },
          {
            path: ':username',
            element: <Gallery />,
          },
        ],
      },

      { path: '/bulkupload', element: <BulkUpload /> },

      // Stream routes
      {
        path: '/watch/:names/:passwords?',
        element: (
          <StreamGuard>
            <Watch />
          </StreamGuard>
        ),
        hydrate: StreamGuardHydrate,
      },
      { path: '/chat/:names', element: <StandaloneChat /> },

      // User routes
      {
        path: '/login',
        element: <Login />,
        children: [
          { index: true, element: <Login /> },
          { path: 'reset_password', element: <Login /> },
          { path: 'resend_confirmation', element: <Login /> },
        ],
      },
      { path: '/signup', element: <SignUp /> },
      { path: '/account/*', element: <Account /> },
      { path: '/signout', element: <SignOut /> },

      { path: '/search', loader() { return redirect('/search/streams'); } },
      { path: '/search/:category?/:query?', element: <Search path="/search/:category?/:query?" /> },
      { path: '/premium', element: <Premium /> },
      { path: '/help/*', element: <Help /> },
      { path: '/optimizations', element: <Optimization /> },

      // Admin
      { path: '/admin/*', element: <AdminPanel /> },

      {
        path: '/tos',
        element: (
          <>
            <Helmet>
              <title>Terms of Service</title>
            </Helmet>
            <Tos />
          </>
        ),
      },

      {
        path: '/privacy',
        element: (
          <>
            <Helmet>
              <title>Privacy Policy</title>
            </Helmet>
            <Privacy />
          </>
        ),
      },

      {
        path: '/styleguide',
        element: (
          <>
            <Helmet>
              <title>Style Guide</title>
            </Helmet>
            <Style />
          </>
        ),
      },

      // {
      //   path: '/mascotcontest/results',
      //   element: <ContestResults />,
      // },

      {
        path: '/player/:streamId',
        element: <Player />,
      },
      {
        path: '/debugplayer',
        element: <DebugPlayer />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default (
  <Switch>
    { Routes.map(route => <Route key={route.path} {...route} />) }
    <Route element={<NotFound />} />
  </Switch>
);
