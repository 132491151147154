/* node_modules */
import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  Route, Routes, Navigate, NavLink,
  useNavigate,
  useLocation,
  Outlet,
} from 'react-router-dom';
import classnames from 'classnames/bind';
import loadable from '@loadable/component';

/* local imports */
import FormattedMessage from '~/components/common/FormattedMessage';

/* style imports */
import styles from './Home.scss';
import { HomepageBanner, HomepageBannerDescription, HomepageBannerImage, HomepageBannerTitle } from '~/components/HomepageBanner';
import Markdown from '~/components/Markdown';

const cx = classnames.bind(styles);

const Home = () => {
  const { isSignedIn } = useSelector(state => state.currentUser);
  const activeBanner = useSelector(state => state.holidayBanners.activeBanner);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/') {
      if (!isSignedIn) {
        navigate('/streams');
      } else {
        navigate('/feed');
      }
    }
  }, [isSignedIn, navigate, pathname]);

  return (
    <div>
      {
        activeBanner ? (
          <HomepageBanner titleOnly={!activeBanner.description}>
            <HomepageBannerTitle>
              {activeBanner.title}
            </HomepageBannerTitle>

            {
              activeBanner.description && (
                <HomepageBannerDescription>
                  <Markdown>
                    {activeBanner.description}
                  </Markdown>
                </HomepageBannerDescription>
              )
            }

            {
              activeBanner.image && (
                <HomepageBannerImage>
                  <img src={activeBanner.image.url} />
                </HomepageBannerImage>
              )
            }
          </HomepageBanner>
        ) : null
      }

      <nav className={styles.Home__SectionPicker}>
        <NavLink
          to="/streams"
          className={({ isActive }) => (`${styles.Home__SectionPickerOption} ${isActive ? styles.Home__SectionPickerOptionActive : ''}`)}
        >
          <i className={cx('ion-ios-film', styles.Home__SectionPickerIcon)} />
          {' '}
          <span className={styles.Home__SectionName}>
            <FormattedMessage id="Home_Streams" defaultMessage="Streams" />
          </span>
        </NavLink>
        {isSignedIn && (
          <NavLink
            to="/feed"
            className={({ isActive }) => (`${styles.Home__SectionPickerOption} ${isActive ? styles.Home__SectionPickerOptionActive : ''}`)}
          >
            <i className={cx('ion-fireball', styles.Home__SectionPickerIcon)} />
            {' '}
            <span className={styles.Home__SectionName}>
              <FormattedMessage id="Home_Feed" defaultMessage="Feed" />
            </span>
          </NavLink>
        )}
        <NavLink
          to="/gallery"
          className={({ isActive }) => (`${styles.Home__SectionPickerOption} ${isActive ? styles.Home__SectionPickerOptionActive : ''}`)}
        >
          <i className={cx('ion-images', styles.Home__SectionPickerIcon)} />
          {' '}
          <span className={styles.Home__SectionName}>
            <FormattedMessage id="Home_Gallery" defaultMessage="Gallery" />
          </span>
        </NavLink>
      </nav>

      <Outlet />
    </div>
  );
};

export default Home;
